<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import { required } from "vuelidate/lib/validators";
import ProjectManagerDetailModal from "./modals/projectmanagerDetail";
import DeletionModal from "@/components/widgets/deletion-modal.vue";
import Repositories from "@/repositories";
const repository = Repositories.get("projectmanagers");

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "projectmanagers",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
    ProjectManagerDetailModal,
    DeletionModal,
  },
  data() {
    return {
      title: "Project Managers",
      isLoading: false,
      projectManagers: [],
      isSubmit: false,
      form: {
        name: null,
      },
    };
  },
  created() {
    //Load progress
    this.isLoading = true;
    repository.get({ name: null })
      .then((response) => {
        this.projectManagers = response.data;
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  mounted() {},
  methods: {
    showDeletionModal(id, index) {
      this.$refs.deleteModal.show().then(() => {
        this.isLoading = true;
        repository.delete(id)
          .then(() => {
            this.projectManagers.splice(index, 1);
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
    },
    submit() {
      this.isSubmit = true;
      this.$v.form.$touch();

      if (!this.$v.$invalid) {
        this.isLoading = true;
        repository.create({ name: this.form.name })
          .then((response) => {
            let projectManager = response.data;
            this.projectManagers.push({ ...projectManager });

            this.form.name = null;
          })
          .finally(() => {
            this.isLoading = false;
            this.isSubmit = false;
          });
      }
    },
    edit(projectManager) {
      this.$refs.detailModal
        .showEdit({ ...projectManager })
        .then((newProjectManager) => {
            projectManager.name = newProjectManager.name;
        });
    },
  },
  validations: {
    form: {
      name: { required },
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('menuitems.configuration.list.projectmanagers')" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <p class="card-title-desc mb-4">
                  {{ $t("general.projectManagersList") }}
                </p>
                <form
                  @submit.prevent="submit"
                  style="display: flex; align-items: flex-end"
                >
                  <div class="form-group" style="position: relative">
                    <b-form-input
                      :class="{
                        'is-invalid':
                          isSubmit && $v.form.name.$error,
                      }"
                      style="max-width: 300px"
                      v-model="form.name"
                      :placeholder="$t('general.name')"
                    >
                    </b-form-input>
                    <div
                      v-if="isSubmit && $v.form.name.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.form.name.required">{{
                        $t("general.requiredValue")
                      }}</span>
                    </div>
                  </div>
                  <b-form-group>
                    <b-button type="submit" class="ml-3" variant="primary">{{
                      $t("general.add")
                    }}</b-button>
                  </b-form-group>
                </form>
              </div>
            </div>

            <div ref="progressTable" class="table-responsive">
              <loading
                :active.sync="isLoading"
                :can-cancel="false"
                color="#f1b44c"
                :width="70"
                :height="70"
                loader="dots"
                :is-full-page="false"
              >
              </loading>
              <table class="table mb-0 table-hover">
                <thead class="thead-light">
                  <tr>
                    <th>{{ $t("general.name") }}</th>
                    <th style="width: 100px; text-align: center">
                      {{ $t("general.action") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(projectManager, index) in projectManagers" :key="index">
                    <td>{{ projectManager.name }}</td>
                    <td style="text-align: center">
                      <b-dropdown
                        dropleft
                        size="lg"
                        class="card-drop"
                        variant="link"
                        toggle-class="text-decoration-none p-0"
                        no-caret
                      >
                        <template #button-content>
                          <i class="fas fa-ellipsis-h" />
                        </template>

                        <b-dropdown-item @click="edit(projectManager)">
                          <i class="fas fa-pencil-alt text-success mr-1"></i>
                          {{ $t("general.edit") }}
                        </b-dropdown-item>

                        <b-dropdown-item
                          @click="showDeletionModal(projectManager.id, index)"
                        >
                          <i class="fas fa-trash-alt text-danger mr-1"></i>
                          {{ $t("general.delete") }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeletionModal
      ref="deleteModal"
      :content="this.$t('general.deleteProjectManagerContent')"
      :title="this.$t('general.deleteProjectManagerTitle')"
    />
    <ProjectManagerDetailModal ref="detailModal" />
  </Layout>
</template>
